<template>
    <div>
        <div class="row justify-content-around">
            <div
                v-for="(indicador, index) in indicadores"
                :key="index"
                class="col-auto animate__animated animate__backInDown"
            >
                <div
                    class="card rounded-circle indicador-soporte p-2 text-center h-100"
                >
                    <div class="my-auto">
                        <p
                            :class="`font-large-2 font-weight-bold mb-1 text-shadow text-${indicador.color}`"
                        >
                            {{ indicador.numero }}
                        </p>
                        <p class="font-medium-2 m-0">{{ indicador.nombre }}</p>
                    </div>
                </div>
            </div>
        </div>
        <b-row class="mt-5">
            <b-col>
                <div class="card p-1">
                    <div class="row">
                        <div class="col">
                            <label for="estado">Estado</label>
                            <v-select
                                placeholder="Seleccionar estado"
                                :options="['Activos', 'Pendienes', 'Cerrados']"
                            ></v-select>
                        </div>
                        <div class="col">
                            <label for="nombre">Nombre</label>
                            <b-form-input
                                placeholder="Nombre del paciente"
                            ></b-form-input>
                        </div>
                        <div class="col">
                            <label for="asunto">Asunto</label>
                            <b-form-input placeholder="Asunto"></b-form-input>
                        </div>
                        <div class="col-auto my-auto">
                            <b-button
                                variant="gradient-success"
                                class="p-50"
                                pill
                            >
                                <feather-icon
                                    icon="SearchIcon"
                                    size="20"
                                ></feather-icon>
                            </b-button>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <div class="d-flex flex-column mb-3">
            <div
                class="card flex-row align-items-center p-1"
                v-for="(_, index) in 7"
                :key="index"
            >
                <div class="col-auto">
                    <span
                        class="font-medium-5 font-weight-bolder font-italic text-success text-shadow mr-50"
                        >#</span
                    >
                    <span class="font-medium-4">1222</span>
                </div>
                <div class="col-auto">
                    <b-avatar variant="light-dark" size="40"></b-avatar>
                </div>
                <div class="col">
                    <p class="font-medium-3 mb-50">Gian Carlos Galán Sánchez</p>
                    <p class="font-weight-bolder mb-50">Nueva eps</p>
                </div>
                <div class="col-6 border-left">
                    <p class="font-weight-bolder mb-25">Asunto:</p>
                    <p class="tres-puntos text-nowrap mb-75">
                        Falta de seretonina
                    </p>
                    <p class="font-weight-bolder mb-25">Descripción:</p>
                    <p v-b-tooltip.hover.top="index" class="tres-puntos mb-75">
                        Este usuario requiere otro dispositivo de mediciónEste
                        usuario requiere otro dispositivo de mediciónEste
                        usuario requiere otro dispositivo de mediciónEste
                        usuario requiere otro dispositivo de mediciónEste
                        usuario requiere otro dispositivo de medición
                    </p>
                </div>

                <div @click="irVerSoporte(index)">
                    <feather-icon
                        icon="ChevronRightIcon"
                        size="50"
                    ></feather-icon>
                </div>
                <!-- TODO: cambiar a absolute todos -->
                <!-- icono para cuando el reporte no sea visto -->
                <div class="position-relative position-right-0 position-top-0">
                    <feather-icon
                        icon="CheckIcon"
                        size="30"
                        class="text-secondary m-50"
                    ></feather-icon>
                </div>
                <!-- icon para cuando el soporte sea visto -->
                <div class="position-relative position-right-0 position-top-0">
                    <feather-icon
                        icon="CheckIcon"
                        size="30"
                        class="text-success m-50"
                    ></feather-icon>
                </div>
                <!-- icono para cuando el soporte esté activo -->
                <div class="position-relative position-right-0 position-top-0">
                    <b-avatar
                        variant="transparent"
                        badge="12"
                        badge-variant="danger"
                        class="m-50"
                    >
                        <feather-icon
                            icon="MessageCircleIcon"
                            size="30"
                            class="text-success"
                        >
                        </feather-icon>
                    </b-avatar>
                </div>
                <!-- icono mensajes usuario(paciente) -->
                <div class="position-relative position-right-0 position-top-0">
                    <b-avatar variant="transparent" class="m-50">
                        <feather-icon
                            icon="MessageCircleIcon"
                            size="30"
                            class="text-success"
                        >
                        </feather-icon>
                    </b-avatar>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import router from "@/router";
import { ref } from "@vue/composition-api";
export default {
    setup(props) {
        const indicadores = ref([
            {
                nombre: "Activos",
                numero: 5,
                color: "success",
            },
            {
                nombre: "Pendientes",
                numero: 100,
                color: "warning",
            },

            {
                nombre: "Cerrados",
                numero: 200,
                color: "primary",
            },
        ]);

        function irVerSoporte(idSoporte) {
            router.push({
                name: "ver-soporte",
                params: { id_soporte: idSoporte },
            });
        }

        return { indicadores, irVerSoporte };
    },
};
</script>
<style lang="css" scope>
.indicador-soporte {
    width: 140px;
}
</style>
